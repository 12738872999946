import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'

import Image from 'components/ui/image'
import RenderBlock from 'components/ui/block-text'

const Footer = ({ data }) => {
  return (
    <footer id="contact" className="main-footer py-5 ">
      <Container className="my-4">
        <Row className="flex-column-reverse flex-md-row">
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column align-items-lg-end pr-md-5"
          >
            <hr className="d-md-none main-footer__separator" />
            <div className="mb-4">
              <Image data={data?.logo} className="" />
            </div>
            <div>
              {data?.social?.map(item => (
                <a key={item?._key} href={item?.link} className="mx-2">
                  <Image data={item?.image} />
                </a>
              ))}
            </div>
            <article className="mt-4">
              <p className="main-footer__description">
                Questions? Call us{' '}
                <a href={`tel:${data?.phone}`}>{data?.phone}</a>
              </p>
            </article>
          </Col>
          <Col xs={12} md={6} className="pl-md-5 main-footer__border-left">
            <hr className="d-md-none main-footer__separator" />
            <Image data={data?.location} className="w-50 mb-4" />
            <RenderBlock
              className="main-footer__description"
              data={data?._rawDescription}
            />
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
export default Footer

Footer.defaultProps = {
  data: {},
}

Footer.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.string,
    social: PropTypes.arrayOf({}),
    phone: PropTypes.string,
    location: PropTypes.string,
    _rawDescription: PropTypes,
  }),
}
