import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Anchor = ({ type = 'link', link, text, className = '', onClick }) => {
  if (type === 'anchor') {
    return (
      <AnchorLink
        href={link}
        className={className}
        offset="80"
        onClick={onClick}
      >
        {text}
      </AnchorLink>
    )
  }
  if (link?.startsWith('tel:') || link?.startsWith('mailto:')) {
    return (
      <a href={link} className={className} onClick={onClick}>
        {text}
      </a>
    )
  }
  return (
    <Link to={link} className={className} onClick={onClick}>
      {text}
    </Link>
  )
}
export default Anchor

Anchor.defaultProps = {
  type: 'link',
  link: null,
  text: null,
  className: null,
  onClick: null,
}

Anchor.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}
