import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const CallUs = () => {
  const data = useStaticQuery(graphql`
    query CallusQuery {
      sanityAppereance(sanityId: { eq: "Home" }) {
        tabs {
          footer {
            phone
          }
        }
      }
    }
  `)
  const phone = data?.sanityAppereance?.tabs?.footer?.phone

  return (
    <a href={`tel:${phone}`} className="main-button default main-callus">
      Call us now! {phone}
    </a>
  )
}

export default CallUs
