import PropTypes from 'prop-types'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import React from 'react'
import Navbar from 'components/navbar'
import Footer from 'components/footer'
import CallUs from 'components/call-us'

const Layout = ({ children, navbar, footer }) => {
  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_GOGGLE_RECAPTCHA_SITE_KEY}
      >
        <Navbar data={navbar} />
        <main>{children}</main>
        <CallUs />
        <Footer data={footer} />
      </GoogleReCaptchaProvider>
    </>
  )
}

export default Layout

Layout.defaultProps = {
  children: <></>,
  navbar: null,
  footer: null,
}

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  navbar: PropTypes.shape({}),
  footer: PropTypes.shape({}),
}
