import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
} from 'reactstrap'
import classnames from 'classnames'
import AnchorLink from 'components/ui/anchor-link'
import Image from 'components/ui/image'

const NavbarCustom = ({ data }) => {
  const { items = [], logo = null, cta = null } = data

  const [isOpen, setIsOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 80) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <header className={classnames({ scrolled, isOpen })}>
      <Container>
        <Navbar dark className="main-navbar" sticky="top" expand="lg">
          <NavbarBrand
            href="/"
            title="Home"
            className="mr-auto main-navbar--brand"
          >
            <figure className="main-navbar--logo m-0 p-0">
              <Image data={logo} />
            </figure>
          </NavbarBrand>
          <NavbarToggler
            className="main-navbar--toggler p-0 w-0"
            onClick={toggle}
          />
          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <Nav
              className="main-navbar--nav mt-4 mt-md-0 d-flex h-75 align-items-center justify-content-center"
              navbar
            >
              {items?.map(item => (
                <NavItem key={item?._key}>
                  <AnchorLink
                    type={item?.type}
                    link={item?.link}
                    text={item?.title}
                    onClick={toggle}
                    className="nav-link text-center text-md-right my-2 my-md-0"
                  />
                </NavItem>
              ))}
            </Nav>
            {cta?.enable && (
              <NavbarText className="px-0 px-md-3 d-block d-lg-inline text-right">
                <a
                  href={cta?.link}
                  className="btn main-button default m-auto text-right main-navbar__cta"
                >
                  {cta?.text}
                </a>
              </NavbarText>
            )}
          </Collapse>
        </Navbar>
      </Container>
    </header>
  )
}

export default NavbarCustom

NavbarCustom.defaultProps = {
  data: {},
}

NavbarCustom.propTypes = {
  data: PropTypes.shape(PropTypes.object),
}
